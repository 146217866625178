import {SearchOutlined} from "@ant-design/icons";
import {Button, ButtonProps} from "antd";
import React from "react";

import {Color} from "../utils/constants";

export default function ButtonSearch(props?: ButtonProps) {
  return (
    <Button
      {...props}
      style={{
        height: "44px",
        borderRadius: "52px",
        width: "171px",
        color: Color.PRIMARY,
        borderColor: Color.PRIMARY,
      }}
      icon={<SearchOutlined/>}
      htmlType="submit"
    >
      Tìm kiếm
    </Button>
  );
}
