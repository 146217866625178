import React, {useEffect, useState} from "react";
import {ISidebarItem} from "../interfaces";
import {AppstoreOutlined, FormOutlined, LogoutOutlined, ShopOutlined, UserOutlined,} from "@ant-design/icons";
import {Avatar, Badge, Drawer, Dropdown, Grid, Layout, Menu, Space, Tooltip, Typography,} from "antd";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {withAuth} from "../hooks/withAuth";
import {PATHNAME} from "../routers/path";
import {getShop, getUser} from "../utils/utility";
import {CardDataService, ShopService} from "../services";
import {toastSuccess} from "../utils/toast";
import axios, {AxiosRequestConfig} from "axios";
import {initAxiosConfig, serviceOptions} from "../services/serviceOptions";
import menuIcon from "../utils/menuIcon";
import {renderStaffGroup} from "../utils";
import {Color} from "../utils/constants";

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;

function DashboardLayout() {
  const routes = getUser()?.menuPermissions.map((item: any) => ({
    ...item,
    icon: menuIcon(item?.title),
  }));

  const router2 = [
    {
      title: "BÁO CÁO DOANH THU",
      visible: true,
      children: [],
      path: "/bao-cao-doanh-thu",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "BÁO CÁO CÔNG NỢ POS",
      visible: true,
      children: [],
      path: "/bao-cao-cong-no-pos",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "BÁO CÁO CÔNG NỢ KH",
      visible: true,
      children: [],
      path: "/bao-cao-cong-no-khach-hang",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "BÁO CÁO THU CHI",
      visible: true,
      children: [],
      path: "/bao-cao-thu-chi",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "BÁO CÁO DÒNG TIỀN",
      visible: true,
      children: [],
      path: "/bao-cao-dong-tien",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "BÁO CÁO KQ KINH DOANH",
      visible: true,
      children: [],
      path: "/bao-cao-ket-qua-kinh-doanh",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "BÁO CÁO CÔNG NỢ ĐỐI TÁC",
      visible: true,
      children: [],
      path: "/bao-cao-cong-no-doi-tac",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "BÁO CÁO CÔNG NỢ CTV",
      visible: true,
      children: [],
      path: "/bao-cao-cong-no-ctv",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "CỬA HÀNG",
      visible: true,
      children: [],
      path: "/manager/branch",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "NHÂN VIÊN",
      visible: true,
      children: [],
      path: "/manager/staff",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "THU CHI",
      visible: true,
      children: [],
      path: "/thu-chi-cong-ty",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "PHÂN QUYỀN",
      visible: true,
      children: [],
      path: "/manager/permission",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "TIỀN ỨNG",
      visible: true,
      children: [],
      path: "/report/debt_aday",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "SỔ QUỸ CÔNG TY",
      visible: true,
      children: [],
      path: "/report/cashbookcompany",
      icon: <AppstoreOutlined/>,
    },
    {
      title: "CẤU HÌNH",
      visible: true,
      children: [
        {
          title: "PHÂN QUYỀN",
          visible: true,
          path: "/manager/permission",
        },
        {
          title: "CỬA HÀNG",
          visible: true,
          path: "/manager/branch",
        },
        {
          title: "NHÂN VIÊN",
          visible: true,
          path: "/manager/staff",
        },
        {
          title: "ĐỐI TÁC",
          visible: true,
          path: "/manager/partner",
        },
        {
          title: "MÁY POS",
          visible: true,
          path: "/manager/pos",
        },
      ],
      icon: <AppstoreOutlined />,
    }
  ];
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[] | undefined>([
    "QUẢN LÝ",
    "CÔNG NỢ",
    "BÁO CÁO",
  ]);
  const screens = Grid.useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();
  const [lstShop, setLstShop] = useState<any[]>();
  const [count, setCount] = useState<number | undefined>(0);
  const submenuChild = (obj: ISidebarItem) => {
    if (obj.children && obj.children.length > 0) {
      const cHtml = obj.children.map((item) => {
        return submenuChild(item);
      });
      return (
        obj.visible && (
          <SubMenu key={obj.title} title={obj.title} icon={obj.icon}>
            {cHtml}
          </SubMenu>
        )
      );
    } else {
      return (
        obj.visible && (
          <Menu.Item key={obj.path} icon={obj.icon}>
            <Link to={obj?.path || "#"}>{obj.title}</Link>
          </Menu.Item>
        )
      );
    }
  };
  const [menu, setMenu] = useState(
    getShop()?.ShopID !== -111
      ? routes.map((obj: any) => {
        if (obj.children && obj.children.length > 0) {
          return submenuChild(obj);
        } else {
          return (
            <Menu.Item key={obj.path} icon={obj.icon}>
              <Link to={obj?.path || "#"}>{obj.title}</Link>
            </Menu.Item>
          );
        }
      })
      : router2.map((obj: any) => {
        if (obj.children && obj.children.length > 0) {
          return submenuChild(obj);
        } else {
          return (
            <Menu.Item key={obj.path} icon={obj.icon}>
              <Link to={obj?.path || "#"}>{obj.title}</Link>
            </Menu.Item>
          );
        }
      })
  );

  const getCount = () => {
    CardDataService.getCountNoti().then((response) => {
      if (response.Result === 1) {
        setCount(response.Data?.CountCard);
      }
    });
  };

  useEffect(() => {
    if (!routes.find((item: any) => item.path === location.pathname)) {
      if (
        routes.find(
          (item: any) =>
            item.children &&
            item.children.find((item: any) => item.path === location.pathname)
        )
      ) {
        const currentSubItem = routes.find(
          (item: any) =>
            item.children &&
            item.children.find((item: any) => item.path === location.pathname)
        );
        // if (currentSubItem?.title) setOpenKeys([currentSubItem.title]);
      }
      return;
    }
  }, [location.pathname]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleOpenChangeMenu = (openKeys: React.Key[]) => {
    setOpenKeys(openKeys as string[]);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };
  const drawerOnClose = () => {
    setDrawerVisible(false);
  };

  const getListShop = () => {
    ShopService.getShopActive()
      .then((response) => {
        if (response.Result === 1) {
          setLstShop(response.Data);
        }
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    // getListShop();
    getCount();
  }, [location.pathname]);

  const changeShop = (shop: any) => {
    localStorage.setItem("shop", JSON.stringify(shop));
    initAxiosConfig();
    toastSuccess("Thay đổi cửa hàng thành công");

    if (shop?.ShopID === -111) {
      setMenu(
        router2.map((obj: any) => {
          if (obj.children && obj.children.length > 0) {
            return submenuChild(obj);
          } else {
            return (
              <Menu.Item key={obj.path} icon={obj.icon}>
                <Link to={obj?.path || "#"}>{obj.title}</Link>
              </Menu.Item>
            );
          }
        })
      );
      navigate("/bao-cao-doanh-thu");
    } else {
      setMenu(
        routes.map((obj: any) => {
          if (obj.children && obj.children.length > 0) {
            return submenuChild(obj);
          } else {
            return (
              <Menu.Item key={obj.path} icon={obj.icon}>
                <Link to={obj?.path || "#"}>{obj.title}</Link>
              </Menu.Item>
            );
          }
        })
      );
      navigate(PATHNAME.ROOT);
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate(PATHNAME.LOGIN, {replace: true});
  };

  const renderTitle = (location: string) => {
    switch (location) {
      case "/contract":
        return "Trang chủ / Đơn hàng";
      case "/cards":
        return "Trang chủ / Data Thẻ";
      case "/collect-expense":
        return "Trang chủ / Thu chi";
      case "/contract/partner":
        return "Trang chủ / Đối tác quẹt";
      case "/manager/collaborators":
        return "Trang chủ / Quản lý / Cộng tác viên";
      case "/manager/partner":
        return "Trang chủ / Quản lý / Đối tác";
      case "/manager/pos":
        return "Trang chủ / Quản lý / Máy Pos";
      case "/partner/configfee":
        return "Trang chủ / Quản lý / Phí đối ứng";
      case "/config-collect-expense":
        return "Trang chủ  / Thu chi / Cấu hình phiếu thu chi khác";
      case "/debt/customer":
        return "Trang chủ  / Công nợ / Khách hàng";
      case "/debt/partner":
        return "Trang chủ  / Công nợ / Đối tác";
      case "/debt/pos":
        return "Trang chủ  / Công nợ / Pos";
      case "/debt/aff":
        return "Trang chủ  / Công nợ / CTV";
      case "/report/turnover":
        return "Trang chủ  / Báo cáo / Doanh thu";
      case "/report/cashbook":
        return "Trang chủ  / Báo cáo / Sổ quỹ";
      case "/report/historycustomerpay":
        return "Trang chủ  / Báo cáo / Lịch sử thu tiền";
      case "/report/reportdaily":
        return "Trang chủ  / Báo cáo / Ngày";
      case "/history/logaction":
        return "Trang chủ / Lịch sử thao tác";
      case "/expensebad/index":
        return "Trang chủ / Chi phí nợ xấu";
      default:
        return "";
    }
  };

  return (
    <Layout>
      {screens.md ? (
        <Sider
          width={250}
          trigger={null}
          breakpoint="lg"
          collapsible
          collapsed={collapsed}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          <div
            className="flex items-center justify-between h-[92px] border border-b-[#ffffff] border-b-solid px-5"
            style={{
              border: "1px solid #ffffff",
            }}
          >
            {!collapsed ? (
              <img
                className="hidden lg:block h-[60px] object-fill mb-2"
                src={require("../assets/images/logo_2.png")}
                onClick={() => {
                  navigate("");
                }}
              />
            ) : null}

            <div
              className="cursor-pointer"
              onClick={() => (!screens.md ? showDrawer() : handleCollapse())}
            >
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="36" height="36" rx="6" fill="white"/>
                <path
                  d="M24.875 13.625H11.125M24.875 18H18M24.875 22.375H11.125"
                  stroke="#5E677C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <Menu
            style={{
              backgroundColor: "#FBFCF5",
            }}
            mode="inline"
            selectedKeys={[location.pathname]}
            openKeys={openKeys}
            onOpenChange={handleOpenChangeMenu}
          >
            {menu}
          </Menu>
        </Sider>
      ) : (
        <Drawer
          placement={"left"}
          closable={false}
          visible={drawerVisible}
          onClose={drawerOnClose}
          bodyStyle={{
            padding: 0,
            background: "#001529",
          }}
        >
          <div className="slider-logo" onClick={() => navigate(PATHNAME.ROOT)}>
            <img
              className="hidden lg:block h-[60px] object-fill mb-2"
              src={require("../assets/images/logo_2.png")}
            />
          </div>
          <Menu
            mode="inline"
            selectedKeys={[location.pathname]}
            onSelect={drawerOnClose}
            openKeys={openKeys}
            onOpenChange={handleOpenChangeMenu}
          >
            {menu}
          </Menu>
        </Drawer>
      )}
      <Layout
        style={{
          marginLeft: !screens.md ? 0 : collapsed ? 80 : 250,
          transition: "all 0.2s",
        }}
      >
        <Header className="header">
          <div className="flex items-center h-[92px] justify-between px-5">
            {!screens.md && (
              <div
                className="cursor-pointer"
                onClick={() => (!screens.md ? showDrawer() : handleCollapse())}
              >
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="36" height="36" rx="6" fill="white"/>
                  <path
                    d="M24.875 13.625H11.125M24.875 18H18M24.875 22.375H11.125"
                    stroke="#5E677C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            )}
            {screens.md && (
              <div>
                <Typography.Text>
                  {renderTitle(location.pathname)}
                </Typography.Text>
              </div>
            )}
            <div className="flex items-center">
              <Space
                style={{
                  lineHeight: 0,
                }}
              >
                <Tooltip title="Đơn hàng cảnh báo">
                  <div
                    className="flex items-center justify-center cursor-pointer rounded-[9px] border-solid border border-[#BCCAD9] h-[40px] w-[40px]"
                    onClick={() => navigate("/contract-cancel")}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.93086 13.1198C0.852406 14.0281 0.166656 15.3888 0.166656 16.9082C0.166656 19.6414 2.38586 21.8606 5.11911 21.8606C6.63849 21.8606 7.99916 21.1749 8.90753 20.0964H17.7632C18.8428 20.0964 19.8779 19.6674 20.6411 18.9042C21.4043 18.141 21.8333 17.1053 21.8333 16.0263V4.26406C21.8333 3.18451 21.4043 2.14939 20.6411 1.38618C19.8779 0.622974 18.8428 0.193974 17.7632 0.193974H6.00095C4.92195 0.193974 3.88628 0.622974 3.12307 1.38618C2.35986 2.14939 1.93086 3.18451 1.93086 4.26406V13.1198ZM5.11911 13.0391C7.25436 13.0391 8.98824 14.7729 8.98824 16.9082C8.98824 19.0434 7.25436 20.7773 5.11911 20.7773C2.98386 20.7773 1.24999 19.0434 1.24999 16.9082C1.24999 14.7729 2.98386 13.0391 5.11911 13.0391Z"
                        fill={Color.ICON}
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.11914 16.1423L4.15064 15.1738C3.93939 14.9625 3.59598 14.9625 3.38473 15.1738C3.17348 15.385 3.17348 15.7284 3.38473 15.9397L4.35323 16.9082L3.38473 17.8767C3.17348 18.0885 3.17348 18.4313 3.38473 18.6426C3.59598 18.8544 3.93939 18.8544 4.15064 18.6426L5.11914 17.6746L6.08764 18.6426C6.29943 18.8544 6.64231 18.8544 6.8541 18.6426C7.06535 18.4313 7.06535 18.0885 6.8541 17.8767L5.8856 16.9082L6.8541 15.9397C7.06535 15.7284 7.06535 15.385 6.8541 15.1738C6.64231 14.9625 6.29943 14.9625 6.08764 15.1738L5.11914 16.1423Z"
                        fill={Color.ICON}
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.41666 2.16667C3.41666 1.0621 4.31209 0.166666 5.41666 0.166666H9.26666V6.03893C9.26666 6.18955 9.20057 6.32838 9.09278 6.40369C8.98553 6.479 8.85282 6.47966 8.74449 6.405L6.61032 4.93088C6.44403 4.81562 6.23928 4.81562 6.07299 4.93088L3.93882 6.405C3.83049 6.47966 3.69778 6.479 3.59053 6.40369C3.48274 6.32838 3.41666 6.18955 3.41666 6.03893V2.16667Z"
                        fill="white"
                      />
                      <rect
                        x="12"
                        y="5.02521"
                        width="6"
                        height="1"
                        rx="0.5"
                        fill="#ABAFB6"
                      />
                      <rect
                        x="10"
                        y="8.02521"
                        width="8"
                        height="1"
                        rx="0.5"
                        fill="#ABAFB6"
                      />
                      <rect
                        x="13"
                        y="11.0252"
                        width="5"
                        height="1"
                        rx="0.5"
                        fill="#ABAFB6"
                      />
                    </svg>
                  </div>
                </Tooltip>
                <Tooltip title="Thẻ hết hạn">
                  <div
                    className="flex items-center justify-center cursor-pointer rounded-[9px] border-solid border border-[#BCCAD9] h-[40px] w-[40px]"
                    onClick={() => navigate("/card/expired")}
                  >
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M23.8333 8.41739V9.74989H2.16666V8.16822C2.16666 5.68739 4.18166 3.68323 6.66249 3.68323H17.3333V6.46739C17.3333 7.84323 18.1567 8.66656 19.5325 8.66656H22.7175C23.1508 8.66656 23.5192 8.59072 23.8333 8.41739Z"
                        fill={Color.ICON}
                      />
                      <path
                        d="M2.16666 9.75V17.8317C2.16666 20.3125 4.18166 22.3167 6.66249 22.3167H19.3375C21.8183 22.3167 23.8333 20.3125 23.8333 17.8317V9.75H2.16666ZM8.66666 18.6875H6.49999C6.05582 18.6875 5.68749 18.3192 5.68749 17.875C5.68749 17.4308 6.05582 17.0625 6.49999 17.0625H8.66666C9.11082 17.0625 9.47916 17.4308 9.47916 17.875C9.47916 18.3192 9.11082 18.6875 8.66666 18.6875ZM15.7083 18.6875H11.375C10.9308 18.6875 10.5625 18.3192 10.5625 17.875C10.5625 17.4308 10.9308 17.0625 11.375 17.0625H15.7083C16.1525 17.0625 16.5208 17.4308 16.5208 17.875C16.5208 18.3192 16.1525 18.6875 15.7083 18.6875Z"
                        fill={Color.ICON}
                      />
                      <path
                        d="M22.7175 1.08333H19.5325C18.1567 1.08333 17.3333 1.90667 17.3333 3.2825V6.4675C17.3333 7.84333 18.1567 8.66667 19.5325 8.66667H22.7175C24.0933 8.66667 24.9167 7.84333 24.9167 6.4675V3.2825C24.9167 1.90667 24.0933 1.08333 22.7175 1.08333ZM23.1183 5.9475C23.2375 6.08833 23.3025 6.24 23.3025 6.42417C23.3025 6.57583 23.2375 6.74917 23.1183 6.86833C22.8692 7.1175 22.4467 7.1175 22.1975 6.86833L21.125 5.79583L20.0633 6.87917C19.8142 7.12833 19.3917 7.12833 19.1317 6.86833C18.8825 6.61917 18.8825 6.19667 19.1317 5.9475L20.215 4.88583L19.1425 3.81333C18.8933 3.56417 18.8825 3.14167 19.1317 2.88167C19.3808 2.62167 19.8033 2.6325 20.0633 2.8925L21.125 3.965L22.2083 2.88167C22.4575 2.6325 22.88 2.6325 23.1292 2.88167C23.2483 3.0225 23.3025 3.185 23.3133 3.35833C23.3133 3.51 23.2483 3.68333 23.14 3.81333L22.0567 4.89667L23.1183 5.9475Z"
                        fill={Color.ICON}
                      />
                    </svg>
                  </div>
                </Tooltip>
                <Badge size="small" count={count}>
                  <div
                    className="flex items-center justify-center cursor-pointer rounded-[9px] border-solid border border-[#BCCAD9] h-[40px] w-[40px]"
                    onClick={() => navigate(PATHNAME.Warning)}
                  >
                    <svg
                      width="28"
                      height="26"
                      viewBox="0 0 28 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_52_1104"
                        maskUnits="userSpaceOnUse"
                        x="3"
                        y="2"
                        width="22"
                        height="22"
                      >
                        <path
                          d="M3.25 2.16667H24.9167V23.8333H3.25V2.16667Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_52_1104)">
                        <path
                          opacity="0.4"
                          d="M16.7971 9.54236C16.733 10.056 16.4757 10.5219 16.0731 10.8523C16.0017 10.911 15.9503 10.9895 15.925 11.0789C15.7959 11.5357 15.6099 11.9762 15.3716 12.3879C15.3003 12.5107 15.2913 12.6597 15.3463 12.7906C15.4023 12.9215 15.5161 13.0181 15.6533 13.0524L16.7917 13.3367C16.919 13.3683 17.0372 13.4144 17.1564 13.4595L17.2692 13.5019C17.3207 13.5209 17.3722 13.529 17.4254 13.529C17.4868 13.529 17.5491 13.5164 17.6069 13.4911C18.0231 13.3087 18.4745 13.1733 18.9475 13.093C19.0351 13.0767 19.1154 13.037 19.1795 12.9774C19.9117 12.2913 20.4208 11.3281 20.5833 10.3449C20.9715 10.2068 21.2658 9.85833 21.3182 9.42861L21.4852 8.0934C21.524 7.77924 21.4265 7.46056 21.2171 7.22222C21.1151 7.10486 20.9896 7.01097 20.8515 6.94597L20.9065 5.8166L21.1259 5.59632C21.5313 5.16569 21.868 4.43083 21.1747 3.37187C20.6511 2.57201 19.7681 2.16667 18.5485 2.16667C18.1007 2.16667 17.5049 2.19375 16.8648 2.41132C16.7366 2.45465 16.6346 2.55305 16.5867 2.68035C16.5389 2.80674 16.5506 2.94847 16.6183 3.06583C17.2358 4.13111 17.1907 5.22618 16.4938 6.06937C16.3547 6.2391 16.3565 6.48194 16.4983 6.64896C16.863 7.08049 17.0336 7.64743 16.9641 8.20715L16.7971 9.54236Z"
                          fill={Color.ICON}
                        />
                        <path
                          d="M15.9126 14.2847C15.8692 14.1285 15.7447 14.0066 15.5885 13.9669L14.0826 13.5895L13.8994 12.8528C14.5575 12.1794 15.0125 11.2757 15.1642 10.3458C15.5533 10.2068 15.8467 9.85924 15.899 9.42951L16.066 8.09431C16.1058 7.77924 16.0083 7.46056 15.7988 7.22222C15.6968 7.10486 15.5713 7.01097 15.4332 6.94597L15.4883 5.8166L15.7076 5.59632C16.113 5.16569 16.4497 4.43083 15.7564 3.37187C15.2328 2.57201 14.3499 2.16667 13.1302 2.16667C12.6599 2.16667 11.581 2.16667 10.5636 2.83653C7.63317 2.90965 7.254 4.35951 7.254 5.62069C7.254 5.90687 7.31629 6.50903 7.36414 6.9225C7.20886 6.9884 7.06803 7.0868 6.95518 7.2168C6.74303 7.45694 6.64372 7.77743 6.68435 8.09521L6.85136 9.43042C6.90824 9.88903 7.23956 10.2537 7.66657 10.3711C7.81733 11.2622 8.24886 12.1333 8.86726 12.7933L8.66775 13.5913L6.17969 14.2124C4.45449 14.6422 3.25018 16.185 3.25018 17.9635C3.25018 18.2126 3.45241 18.4149 3.70157 18.4149L13.7044 18.4158C13.9292 18.4158 14.1197 18.2506 14.1513 18.0285C14.3318 16.7646 14.9015 15.6217 15.797 14.7243C15.9126 14.6087 15.9568 14.4417 15.9126 14.2847Z"
                          fill={Color.ICON}
                        />
                        <path
                          d="M19.9515 13.9028C17.2143 13.9028 14.9863 16.1308 14.9863 18.8681C14.9863 21.6053 17.2143 23.8333 19.9515 23.8333C22.6888 23.8333 24.9168 21.6053 24.9168 18.8681C24.9168 16.1308 22.6888 13.9028 19.9515 13.9028ZM19.4984 16.1597C19.4984 15.9106 19.7006 15.7083 19.9497 15.7083C20.1989 15.7083 20.4011 15.9106 20.4011 16.1597V19.7708C20.4011 20.02 20.1989 20.2222 19.9497 20.2222C19.7006 20.2222 19.4984 20.02 19.4984 19.7708V16.1597ZM20.2675 21.8924C20.1863 21.9826 20.0689 22.0278 19.9515 22.0278C19.8342 22.0278 19.7168 21.9826 19.6347 21.9005C19.5453 21.8111 19.5002 21.6938 19.5002 21.5764C19.5002 21.459 19.5453 21.3417 19.6265 21.2595C19.8071 21.0889 20.0969 21.0889 20.2675 21.2505C20.3479 21.3417 20.4029 21.459 20.4029 21.5764C20.4029 21.6938 20.3479 21.8111 20.2675 21.8924Z"
                          fill={Color.ICON}
                        />
                      </g>
                    </svg>
                  </div>
                </Badge>
                {/*<Tooltip title="Lịch hẹn">*/}
                {/*  <div*/}
                {/*    className="flex items-center justify-center cursor-pointer rounded-[9px] border-solid border border-[#BCCAD9] h-[40px] w-[40px]"*/}
                {/*    onClick={() => navigate("/schedule-payment")}*/}
                {/*  >*/}
                {/*    <svg*/}
                {/*      fill="#54689A"*/}
                {/*      height="20px"*/}
                {/*      width="20px"*/}
                {/*      version="1.1"*/}
                {/*      id="Capa_1"*/}
                {/*      xmlns="http://www.w3.org/2000/svg"*/}
                {/*      viewBox="0 0 611.999 611.999"*/}
                {/*    >*/}
                {/*      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>*/}
                {/*      <g*/}
                {/*        id="SVGRepo_tracerCarrier"*/}
                {/*        stroke-linecap="round"*/}
                {/*        stroke-linejoin="round"*/}
                {/*      ></g>*/}
                {/*      <g id="SVGRepo_iconCarrier">*/}
                {/*        {" "}*/}
                {/*        <g>*/}
                {/*          {" "}*/}
                {/*          <g>*/}
                {/*            {" "}*/}
                {/*            <g>*/}
                {/*              {" "}*/}
                {/*              <path*/}
                {/*                d="M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203 C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578 c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626 h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856 c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626 C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32 c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082 c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826 c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485 c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z"></path>*/}
                {/*              {" "}*/}
                {/*              <path*/}
                {/*                d="M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258 c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258 C323.259,126.96,315.532,119.235,306.001,119.235z"></path>*/}
                {/*              {" "}*/}
                {/*            </g>*/}
                {/*            {" "}*/}
                {/*          </g>*/}
                {/*          {" "}*/}
                {/*        </g>*/}
                {/*        {" "}*/}
                {/*      </g>*/}
                {/*    </svg>*/}
                {/*  </div>*/}
                {/*</Tooltip>*/}
              </Space>
              <div className="ml-[24px]">
                <svg
                  width="1"
                  height="33"
                  viewBox="0 0 1 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="1" height="50" fill="#BCCAD9"/>
                </svg>
              </div>

              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key={"1"}>
                      <Link
                        to={PATHNAME.CHANGE_PASSWORD}
                        className="flex items-center"
                      >
                        <FormOutlined className="mr-2"/> Đổi mật khẩu
                      </Link>
                    </Menu.Item>
                    <Menu.Divider/>
                    <Menu.Item
                      key="setting:2"
                      className="flex items-center"
                      onClick={() => logout()}
                    >
                      <Link to={"#"} className="flex items-center">
                        <LogoutOutlined className="mr-2"/> Đăng xuất
                      </Link>
                    </Menu.Item>
                  </Menu>
                }
                placement="bottom"
              >
                <div className="flex items-center gap-[12px] ml-[24px]">
                  <Avatar size={40} icon={<UserOutlined/>}/>
                  {screens.md && (
                    <>
                      <div style={{fontWeight: "600", fontSize: "14px"}}>
                        {getUser()?.FullName}
                        <br/>
                        <div
                          style={{
                            color: "#718096",
                            fontSize: "12px",
                            fontWeight: "normal",
                          }}
                        >
                          {renderStaffGroup(getUser()?.GroupID)}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Dropdown>
              {/*<Dropdown*/}
              {/*  overlay={*/}
              {/*    <Menu>*/}
              {/*      {getUser()?.lstShop?.map((item: any) => (*/}
              {/*        <Menu.Item onClick={() => changeShop(item)}>*/}
              {/*          <Link to={"#"} className="flex items-center">*/}
              {/*            <ShopOutlined className="mr-2"/>*/}
              {/*            {item.ShopName}*/}
              {/*            <svg*/}
              {/*              width="12"*/}
              {/*              height="12"*/}
              {/*              viewBox="0 0 12 12"*/}
              {/*              fill="none"*/}
              {/*              xmlns="http://www.w3.org/2000/svg"*/}
              {/*            >*/}
              {/*              <path*/}
              {/*                d="M8.96036 4.08984H5.84536H3.04036C2.56036 4.08984 2.32036 4.66984 2.66036 5.00984L5.25036 7.59984C5.66536 8.01484 6.34036 8.01484 6.75536 7.59984L7.74036 6.61484L9.34536 5.00984C9.68036 4.66984 9.44036 4.08984 8.96036 4.08984Z"*/}
              {/*                fill="#444D61"*/}
              {/*              />*/}
              {/*            </svg>*/}
              {/*          </Link>*/}
              {/*        </Menu.Item>*/}
              {/*      ))}*/}
              {/*    </Menu>*/}
              {/*  }*/}
              {/*  trigger={["click"]}*/}
              {/*>*/}
              {/*  <Tooltip placement="leftTop" title="Chuyển cửa hàng khác">*/}
              {/*    <div*/}
              {/*      className="flex items-center gap-2 border-dashed border px-5 py-1 rounded-xl border-[#BECCDA] ml-[24px]">*/}
              {/*      <svg*/}
              {/*        width="16"*/}
              {/*        height="16"*/}
              {/*        viewBox="0 0 16 16"*/}
              {/*        fill="none"*/}
              {/*        xmlns="http://www.w3.org/2000/svg"*/}
              {/*      >*/}
              {/*        <g clip-path="url(#clip0_42_32657)">*/}
              {/*          <path*/}
              {/*            d="M12.667 11.3333C11.9647 11.3331 11.2749 11.1477 10.667 10.796C10.0591 11.1477 9.36929 11.3331 8.66699 11.3333H7.33366C6.63039 11.3324 5.94005 11.1442 5.33366 10.788C4.72729 11.1443 4.03694 11.3325 3.33366 11.3333H2.66699C1.96407 11.3331 1.27385 11.146 0.666992 10.7913V14C0.666992 14.5304 0.877706 15.0391 1.25278 15.4142C1.62785 15.7893 2.13656 16 2.66699 16H13.3337C13.8641 16 14.3728 15.7893 14.7479 15.4142C15.1229 15.0391 15.3337 14.5304 15.3337 14V10.792C14.7266 11.1462 14.0365 11.333 13.3337 11.3333H12.667Z"*/}
              {/*            fill="url(#paint0_linear_42_32657)"*/}
              {/*          />*/}
              {/*          <path*/}
              {/*            d="M11.3333 0V4H10V0H6V4H4.66667V0H1.46667L0.016 6.522L0 7.33333C0 8.04058 0.280952 8.71885 0.781049 9.21895C1.28115 9.71905 1.95942 10 2.66667 10H3.33333C3.71326 9.99877 4.08849 9.91587 4.43356 9.7569C4.77864 9.59794 5.0855 9.36663 5.33333 9.07867C5.58117 9.36663 5.88803 9.59794 6.23311 9.7569C6.57818 9.91587 6.9534 9.99877 7.33333 10H8.66667C9.04521 10.0002 9.41944 9.9196 9.76434 9.76359C10.1092 9.60758 10.4169 9.37975 10.6667 9.09533C10.9165 9.37975 11.2241 9.60758 11.569 9.76359C11.9139 9.9196 12.2881 10.0002 12.6667 10H13.3333C14.0406 10 14.7189 9.71905 15.219 9.21895C15.719 8.71885 16 8.04058 16 7.33333V6.66667L14.5333 0H11.3333Z"*/}
              {/*            fill="url(#paint1_linear_42_32657)"*/}
              {/*          />*/}
              {/*        </g>*/}
              {/*        <defs>*/}
              {/*          <linearGradient*/}
              {/*            id="paint0_linear_42_32657"*/}
              {/*            x1="8.00033"*/}
              {/*            y1="10.788"*/}
              {/*            x2="8.00033"*/}
              {/*            y2="16"*/}
              {/*            gradientUnits="userSpaceOnUse"*/}
              {/*          >*/}
              {/*            <stop stop-color="#3169F6"/>*/}
              {/*            <stop*/}
              {/*              offset="1"*/}
              {/*              stop-color="#284799"*/}
              {/*              stop-opacity="0.16"*/}
              {/*            />*/}
              {/*          </linearGradient>*/}
              {/*          <linearGradient*/}
              {/*            id="paint1_linear_42_32657"*/}
              {/*            x1="8"*/}
              {/*            y1="0"*/}
              {/*            x2="8"*/}
              {/*            y2="10"*/}
              {/*            gradientUnits="userSpaceOnUse"*/}
              {/*          >*/}
              {/*            <stop stop-color="#3169F6"/>*/}
              {/*            <stop*/}
              {/*              offset="1"*/}
              {/*              stop-color="#284799"*/}
              {/*              stop-opacity="0.16"*/}
              {/*            />*/}
              {/*          </linearGradient>*/}
              {/*          <clipPath id="clip0_42_32657">*/}
              {/*            <rect width="16" height="16" fill="white"/>*/}
              {/*          </clipPath>*/}
              {/*        </defs>*/}
              {/*      </svg>*/}
              {/*      {getShop()?.ShopName}*/}
              {/*    </div>*/}
              {/*  </Tooltip>*/}
              {/*</Dropdown>*/}
            </div>
          </div>
        </Header>
        <Content>
          <Outlet/>
        </Content>
      </Layout>
    </Layout>
  );
}

export default withAuth(DashboardLayout);
