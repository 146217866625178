/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

import axios, {AxiosRequestConfig} from "axios";
import {baseURL} from "./api";
import {getShop, getUser} from "../utils/utility";

export interface IRequestOptions {
  headers?: any;
}

export interface IRequestPromise<T = any> extends Promise<IRequestResponse<T>> {
}

export interface IRequestResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: any;
  request?: any;
}

export interface IRequestInstance {
  (config: any): IRequestPromise;

  (url: string, config?: any): IRequestPromise;

  request<T = any>(config: any): IRequestPromise<T>;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: IRequestInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function requestAxios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
        if (err?.response?.status === 401) {
          localStorage.clear()
          window.location.href = '/login'
        }
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {...options, method, url};
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };

  return configs;
}


export function initAxiosConfig() {
  if (serviceOptions.axios) return;

  const axiosConfig: AxiosRequestConfig = {
    baseURL: baseURL,
    timeout: 60000, // 1 phút
  };

  axiosConfig.headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Access-Control-Allow-Origin": "*",
    UserID: getUser()?.UserID || "",
    ShopID: getShop()?.ShopID || "",
  };

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log("Error", error);
      // return Promise.reject(error);
    }
  );

  serviceOptions.axios = axios.create(axiosConfig);
}

initAxiosConfig();