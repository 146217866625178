import React, {useEffect, useState} from "react";
import {DeleteOutlined, DownloadOutlined, EditOutlined, PlusCircleOutlined, UploadOutlined,} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Grid,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  TablePaginationConfig,
  Tooltip,
  Typography,
} from "antd";
import Content from "../../components/Content";
import Page from "../../components/Page";
import {AlignType} from "rc-table/lib/interface";
import dayjs from "dayjs";
import {toastError, toastSuccess} from "../../utils/toast";
import ButtonSearch from "../../components/ButtonSearch";
import {emptyTable} from "../../utils/emptyTable";
import {formatDDMMYYYHHmm} from "../../utils/utility";
import {ZoneService} from "../../services/zone";
import {baseURL} from "../../services/api";

const {Option} = Select;

export default function ManagerZone() {
  const title = "QUẢN LÝ CẤU KHU VỰC";
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const screens = Grid.useBreakpoint();

  const handleTableChange = (pagination1: TablePaginationConfig) => {
    //@ts-ignore
    setPagination(pagination1);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [zoneName, setZoneName] = useState("");

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOnChangeFile = async (e: any) => {
    const file = e.target.files[0];

    if (file) {
      ZoneService.uploadExcel({
        file: file
      }).then((response) => {
        if (response.Result === 1) {
          toastSuccess(response?.Message || "");
          getData();
        } else {
          toastError(response.Message);
        }
      });
    }

    e.target.value = null;
  };

  const showModalEdit = async (item: any) => {
    setItemSelected(item);
    form.setFieldsValue({
      name: item.ZoneName,
    });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setItemSelected(null);
    setIsModalVisible(false);
    form.resetFields();
  };

  const columns = [
    {
      title: "STT",
      align: "center" as AlignType,
      render: (_: any, record: any, index: number) => (
        //@ts-ignore
        <>{(pagination.current - 1) * 10 + index + 1}</>
      ),
    },
    {
      title: "Tên khu vực",
      dataIndex: "ZoneName",
      key: "ZoneName",
    },
    {
      title: "Ngày tạo",
      dataIndex: "CreateDate",
      key: "CreateDate",
      render: (_: any, record: any) => (
        <>{formatDDMMYYYHHmm(dayjs(record.CreateDate))}</>
      ),
    },
    {
      title: "Chức Năng",
      key: "action",
      align: "center" as AlignType,
      render: (_: any, record: any) => (
        <Space size="middle">
          <Tooltip title="Sửa">
            <Button
              icon={<EditOutlined/>}
              onClick={() => showModalEdit(record)}
            />
          </Tooltip>
          <Popconfirm
            title={`Bạn có chắc chắn muốn xoá khu vực ${record?.ZoneName}?`}
            okText="Xoá"
            cancelText="Huỷ"
            onConfirm={() => {
              ZoneService.deleteZone({zoneId: record.ZoneID}).then(
                (response) => {
                  if (response.Result === 1) {
                    toastSuccess("Xoá khu vực thành công");
                    getData();
                  } else {
                    //@ts-ignore
                    toastError(response.Message);
                  }
                }
              );
            }}
          >
            <Tooltip title="Xoá">
              <Button icon={<DeleteOutlined/>}/>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);
    ZoneService.getZones({
      generalSearch: zoneName,
      pageSize: pagination.pageSize,
      pageIndex: pagination.current,
    })
      .then((response) => {
        setIsLoading(false);
        if (response.Result === 1) {
          //@ts-ignore
          setListData(response.Data);
          //@ts-ignore
          setPagination({...pagination, total: response.Total});
        } else {
          //@ts-ignore
          toastError(response.Message);
        }
      })
      .catch((err) => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, [pagination.current, zoneName]);

  const onFinish = (values: any) => {
    ZoneService.addOrEdit({
      body: {
        //@ts-ignore
        ZoneID: itemSelected ? itemSelected.ZoneID : 0,
        ZoneName: values.name,
      },
    }).then((response) => {
      if (response.Result === 1) {
        toastSuccess(
          itemSelected
            ? "Cập nhật khu vực thành công"
            : "Thêm mới khu vực thành công"
        );
        getData();
        form.resetFields();
        setItemSelected(null);
      } else {
        toastError(response.Message);
      }
    });
    setItemSelected(null);
    setIsModalVisible(false);
  };

  useEffect(() => {
    // getData();
  }, [pagination.current]);

  return (
    //@ts-ignore
    <Page title={"VDI - " + title}>
      <Content title={title}>
        <Row
          justify="space-between"
          style={{marginBottom: 24, alignItems: "center"}}
        >
          <Typography.Text strong style={{fontSize: "18px"}}>
            Khu vực
          </Typography.Text>
          <Space direction={screens.md ? "horizontal" : "vertical"}>
            <Button
              type={"primary"}
              style={{
                height: "40px",
                width: "120px",
                borderRadius: "6px",
              }}
              icon={<DownloadOutlined/>}
              href={baseURL + "/file/template/zone_template.xlsx"}
            >
              File mẫu
            </Button>
            <Button
              type={"primary"}
              style={{
                borderRadius: "6px",
                height: "40px",
                width: "120px"
              }}
            >
              <label htmlFor="icon-button-parter-excel-file">
                <UploadOutlined/>
                <input
                  id="icon-button-parter-excel-file"
                  style={{
                    display: "none",
                  }}
                  type="file"
                  accept=".xlsx"
                  onChange={handleOnChangeFile}
                />
                Tải lên
              </label>
            </Button>
            <Button
              type="primary"
              onClick={() => showModal()}
              style={{
                borderRadius: "6px",
                borderColor: "#C1D2FC",
                height: "40px",
              }}
              icon={<PlusCircleOutlined/>}
            >
              Thêm mới
            </Button>
          </Space>
        </Row>
        <Card
          style={{
            marginBottom: 24,
          }}
        >
          <Form layout="vertical" onFinish={getData}>
            <Row gutter={[24, 16]}>
              <Col xs={24} md={12} lg={6}>
                <Form.Item label="Tên khu vực">
                  <Input
                    value={zoneName}
                    placeholder="Tìm theo tên khu vực"
                    onChange={(e) => setZoneName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <Form.Item label={
                  <Typography.Text style={{color: "white"}}>
                    T
                  </Typography.Text>
                }>
                  <ButtonSearch htmlType="submit"/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card>
          <Table
            dataSource={listData}
            columns={columns}
            size="middle"
            locale={emptyTable}
            pagination={{
              ...pagination,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} của ${Intl.NumberFormat(
                  "vi-VN"
                ).format(total)}`,
              showSizeChanger: false
            }}
            loading={isLoading}
            onChange={handleTableChange}
            scroll={{x: 1000}}
          />
        </Card>
        <Modal
          title={itemSelected ? "Cập nhật khu vực" : "Thêm mới khu vực"}
          visible={isModalVisible}
          onOk={form.submit}
          onCancel={handleCancel}
          okText={itemSelected ? "Cập nhật" : "Thêm mới"}
          cancelText={"Hủy"}
          width={600}
          footer={false}
        >
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
              label="Tên khu vực"
              name="name"
              rules={[
                {required: true, message: "Vui lòng nhập tên khu vực!"},
              ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button
                  type={"default"}
                  style={{
                    height: "40px",
                    borderRadius: "6px",
                    width: 120,
                  }}
                  onClick={handleCancel}
                >
                  Huỷ
                </Button>
                <Button
                  style={{
                    height: "40px",
                    borderRadius: "6px",
                    width: 120,
                  }}
                  type="primary"
                  onClick={form.submit}
                >
                  {itemSelected ? "Cập nhật" : "Thêm mới"}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </Content>
    </Page>
  );
}
