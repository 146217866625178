import React from "react";
import {Col, Row} from "antd";
import {ContentButtonProps} from "../interfaces";

interface Props {
  title?: string;
  subTitle?: string;
  buttons?: ContentButtonProps[];
  endButtons?: ContentButtonProps[];
  children?: any;
  className?: string;
  footer?: JSX.Element;
}

const Content = ({
                   title,
                   buttons,
                   children,
                   subTitle,
                   endButtons,
                   footer,
                 }: Props) => {
  let extraButtons: ContentButtonProps[] = [];
  if (buttons) {
    extraButtons = [...extraButtons, ...buttons];
  }
  if (endButtons) {
    extraButtons = [...extraButtons, ...endButtons];
  }
  return (
    <>
      {/* <PageHeader
        ghost={false}
        title={title}
        subTitle={subTitle}
        extra={extraButtons.map((button, index) => {
          if (button.visible) {
            if (button.href)
              return (
                <Link to={button.href}>
                  <Button
                    key={`content-button-${index}`}
                    type={button.type}
                    icon={button.icon}
                    className={button.className}
                  >
                    {button.text}
                  </Button>
                </Link>
              );
            return (
              <Button
                key={`content-button-${index}`}
                type={button.type}
                onClick={button.onClick}
                icon={button.icon}
                className={button.className}
              >
                {button.text}
              </Button>
            );
          }
        })}
        footer={footer}
      /> */}
      <Row
        style={{
          margin: 24,
        }}
      >
        <Col span={24}>{children}</Col>
      </Row>
    </>
  );
};

export default Content;
