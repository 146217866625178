import React from "react";
import "./App.css";
import Router from "./routers";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {ConfigProvider} from "antd";
import viVN from "antd/locale/vi_VN";
import {Color} from "./utils/constants";

function App() {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: Color.PRIMARY,
          },
          components: {
            Table: {
              headerBg: Color.PRIMARY,
              headerColor: '#fff',
              headerBorderRadius: 0,
              // headerFontWeight: 500,
            },
            Select: {
              borderRadius: 8,
              controlHeight: 44,
            },
            Layout: {
              colorBgHeader: Color.PRIMARY, // Header background
              headerHeight: 64,
            },
            Menu: {
              itemActiveBg: 'white',
              // itemColorActive: '#1890ff',
              itemBorderRadius: 8,
              itemHeight: 44,
            },
            Modal: {
              // headerBg: '#5c88f8',
              // headerColor: '#fff',
              borderRadius: 8,
            },
            Tag: {
              borderRadius: 4,
              padding: 8
              // paddingInline: 8,
            },
            InputNumber: {
              borderRadius: 8,
              controlHeight: 44,
            },
            Input: {
              borderRadius: 8,
              controlHeight: 44,
            },
            DatePicker: {
              borderRadius: 8,
              controlHeight: 44,
            },
            Card: {
              borderRadius: 6,
            },
          }
        }}
      >
        <Router/>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </ConfigProvider>
    </>
  );
}

export default App;
