export const PATHNAME = {
  LOGIN: "/login",
  ROOT: "/",
  REGISTER: "/register",
  MANAGER: "/manager",
  MANAGER_BRANCH: "/manager/branch",
  MANAGER_STAFF: "/manager/staff",
  MANAGER_PERMISSION: "/manager/permission",
  MANAGER_BANK: "/manager/bank",
  MANAGER_ZONE: "/manager/zone",
  MANAGER_COLLABORATOR: "/manager/collaborators",
  MANAGER_POS: "/manager/pos",
  CREATE_STAFF: "manager/staff/create",
  CHANGE_PASSWORD: "changepassword",
  Customer: "/customer",
  Partner: "/manager/partner",
  CARD: "/cards",
  ThuChi: "/collect-expense",
  ConfigCollectExpense: "/config-collect-expense",
  Contract: "/contract",
  Debt: "/debt",
  REPORT: "/report",
  REPORT_TURNOVER: "/report/turnover",
  REPORT_CASHBOOK: "/report/cashbook",
  REPORT_DAILY: "/report/reportdaily",
  REPORT_DAILY_ADVANCE_PAYMENT: "/report/advance-payment",
  PostManager: "/manager/pospartner",
  Warning: "/warning",
  DebtCompare: "/debt/generalcomparison",
  DEBT_ADVANCE_PAYMENT: "/debt/advance-payment",
  ReportAllShop: "/report/reportallshop",
  Company: "https://ag.cardpro.ai/",
  SchedulePayment: "/schedule-payment",
  UNAUTHORIZED: "/unauthorized"
}
