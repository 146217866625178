import {requestAxios, getConfigs, IRequestConfig, IRequestOptions} from "./serviceOptions";
import {basePath, ResponseData} from "./index";


export interface ZoneItemListResponseResultData {
  /** Result = 1 Thanh công , -1 : lỗi, -11 : hết token đẩy ra ngoài */
  Result?: number;

  /** Message thông báo */
  Message?: string;

  /**  */
  Total?: number;

  /**  */
  TotalData?: any | null;

  /**  */
  Data?: ZoneItem[];
}

export interface ZoneItem {
  /**  */
  ZoneID?: number;

  /**  */
  ZoneName?: string;

  /**  */
  CreateDate?: Date;
}

export interface ZoneCreateRequest {
  /**  */
  ZoneID?: number;

  /**  */
  ZoneName?: string;
}

export class ZoneService {
  /**
   * Danh sách khu vực
   */
  static getZones(
    params: {
      /** Tìm theo số thẻ,chủ thẻ */
      generalSearch?: string;
      /** Trang số bao nhiêu */
      pageIndex?: number;
      /** Bao nhiêu bản ghi */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ZoneItemListResponseResultData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/zone/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeneralSearch: params['generalSearch'],
        PageIndex: params['pageIndex'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      requestAxios(configs, resolve, reject);
    });
  }

  /**
   * Thêm ,update nhóm
   */
  static addOrEdit(
    params: {
      /**  */
      body?: ZoneCreateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/zone/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      requestAxios(configs, resolve, reject);
    });
  }

  /**
   * Xóa nhóm
   */
  static deleteZone(
    params: {
      /** GroupID */
      zoneId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/zone/';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = {ZoneID: params['zoneId']};

      /** 适配ios13，get请求不允许带body */

      requestAxios(configs, resolve, reject);
    });
  }

  static uploadExcel(
    params: {
      /**  */
      file?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/v1/zone/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      const data = new FormData();
      data.append('file', params['file']);
      configs.data = data;

      requestAxios(configs, resolve, reject);
    });
  }
}
