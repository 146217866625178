import Content from "./Content";
import {Card, Col, Row, Typography} from "antd";
import React from "react";
import Page from "./Page";

export default function UnauthorizedPage() {
  return (
    //@ts-ignore
    <Page title={"Unauthorized"}>
      <Content>
        <Row
          justify="center"
          align="middle"
          style={{height: '100%'}}
        >
          <Col span={24}>
            <Card
              style={{
                textAlign: 'center',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* Replace this SVG with your actual SVG */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="12"></line>
                <line x1="12" y1="16" x2="12" y2="16"></line>
              </svg>
              <Typography.Text strong style={{display: 'block', marginTop: 16, fontSize: 24}}>
                Không đủ quyền hạn
              </Typography.Text>
            </Card>
          </Col>
        </Row>
      </Content>
    </Page>
  );
};
